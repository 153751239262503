// MEDIA QUERY MANAGER
/*
0-600px        phone
600-740px      phone-land
600-900px      table portrait
900-1200px     table landscape
1200-1800px    is where normal styles apply
1800px +       big screen

Em is used for media queries because rem fails in some browsers
In media queries em and rem are not affected by the root font-size setting
1em or 1rem is equal to the font-size coming from the browser which is 16px by default
*/

@mixin responsive($breakpoint) {
  @if $breakpoint == phone-port {
    @media only screen and (max-width: 37.5em) {
      @content;
    } // 600px/16 = 37.5
  }

  @if $breakpoint == phone-land {
    @media only screen and (max-width: 46.25em) {
      @content;
    } // 740px/16 = 46.25
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } // 900px/16 = 56.25
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } // 1200px/16 = 75
  }

  // media for 14+ inches screen size
  @if $breakpoint == large-screen {
    @media only screen and (min-width: 84em) {
      @content;
    } //1344px/16 = 84
  }

  // media for 17+ inches screen size
  @if $breakpoint == larger-screen {
    @media only screen and (min-width: 102em) {
      @content;
    } //1632px/16 = 102
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px/16 = 112.5
  }
}
