.hero {
  height: 100vh;
  background-color: $color-primary;
  overflow: hidden;

  @include responsive(tab-port) {
    height: 60vh;
  }

  @include responsive(phone-land) {
    height: 100vh;
  }

  @include responsive(phone-port) {
    height: 55vh;
  }

  &__intro {
    width: 70rem;
    height: 20rem;
    position: absolute;
    top: 50%;
    margin-top: -10rem;
    left: 50%;
    margin-left: -35rem;

    @include responsive(tab-port) {
      top: 30%;
    }

    @include responsive(phone-land) {
      top: 50%;
    }

    @include responsive(phone-port) {
      width: 100%;
      left: 50;
      margin-left: -50%;
      margin-top: -15rem;
    }

    @include responsive(phone-port) {
      top: 35%;
    }
  }
}
