.heading {
  width: 45%;
  height: 8rem;
  margin-bottom: 4.5rem;
  position: relative;

  @include responsive(tab-port) {
    margin-bottom: 3.5rem;
  }

  @include responsive(tab-port) {
    width: 60%;
  }

  @include responsive(phone-port) {
    width: 85%;
    height: 6rem;
  }

  &__bar--about {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $color-primary;
  }

  &__bar--projects {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $color-primary;
  }

  &__number {
    font-size: 6rem;
    font-weight: 600;
    margin-left: auto;
    margin-right: 3rem;
    color: $color-secondary;
    z-index: 5;
  }

  &__title {
    color: $color-primary;
    font-family: "The Bold Font";
    font-size: 4.5rem;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(0, -50%);
    z-index: 0;

    @include responsive(phone-port) {
      font-size: 3.5rem;
    }
  }
}
