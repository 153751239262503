.burger-menu {
  position: relative;

  &__nav {
    display: none;
    animation: fade-in 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  &__nav--toggled {
    display: block;
    animation: fade-in 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  &__nav-wrapper {
    width: 0rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    position: absolute;
    top: -90%;
    right: 4.3rem;
    cursor: default;

    @include responsive(large-screen) {
      top: -100%;
    }
  }

  &__bar {
    width: 3rem;
    height: 2px;
    background-color: $color-secondary;
    margin: 6px 0;
    transition: 0.4s;
  }
}

// transform slices into a cross mark
.toggled .burger-menu__bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.toggled .burger-menu__bar:nth-child(2) {
  opacity: 0;
}

.toggled .burger-menu__bar:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -7px);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
