.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @include responsive(tab-port) {
    justify-content: start;
  }

  @include responsive(phone-port) {
    place-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}

.card {
  width: calc(100% / 1 - 1rem);
  height: 46rem;
  position: relative;
  border: 1px solid #d0d0d0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  @include responsive(tab-port) {
    width: calc(100% / 1 - 1rem);
  }

  @include responsive(tab-land) {
    height: 50rem;
  }

  @include responsive(phone-port) {
    margin-left: 0;
  }

  @include responsive(big-desktop) {
    height: 50rem;
  }
}

.card:not(:last-child) {
  margin-right: 1rem;

  @include responsive(tab-port) {
    margin-right: 0;
  }

  @include responsive(phone-port) {
    margin-bottom: 2rem;
  }
}

.card:first-child {
  @include responsive(tab-port) {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  @include responsive(phone-port) {
    margin-right: 0;
  }
}

.card-thumbnail {
  width: 100%;
  height: 60%;

  &__img {
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
}

.card-description {
  height: 40%;
  color: $color-dark;
  font-size: 1.4rem;
  background-color: $color-white;
  padding: 1rem;
  text-align: center;
  position: relative;

  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__link {
    text-decoration: none;
    color: #404040;
  }

  &__link:hover {
    text-decoration: underline;
  }

  &__link-icon {
    font-size: 1.8rem;
    color: $color-grayed-out;
    text-decoration: none;
    position: absolute;
    bottom: 1rem;
    transform: translateX(-50%);
    transition: 0.2s;
  }

  &__link-icon:hover {
    color: $color-dark;
  }

  &__icon {
    margin-left: 0.5rem;
  }
}
