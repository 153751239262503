*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, (10/16) * 100 = 62.5
  -webkit-font-smoothing: antialiased;

  @include responsive(tab-land) {
    font-size: 56.25%; //1rem = 9px, (9/16) * 100 = 56.25
  }

  @include responsive(tab-port) {
    font-size: 50%; //1rem = 8px, (8/16) * 100 = 50
  }

  @include responsive(phone) {
    font-size: 44%; //1rem = 7px, (7/16) * 100 = 44
  }

  @include responsive(large-screen) {
    font-size: 75%; //1rem = 12px,(12/16) * 100 = 75
  }
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.4;
}
