.container {
  display: flex;
  height: fit-content;
  padding-right: 1rem;
  padding-left: 6rem;
  background-color: $color-secondary;

  @include responsive(tab-land) {
    padding-left: 3rem;
  }

  @include responsive(tab-port) {
    padding: 0 2.5rem;
  }

  @include responsive(phone-land) {
    padding-bottom: 2rem;
  }

  @include responsive(larger-screen) {
    padding-left: 10rem;
  }

  @include responsive(big-desktop) {
    padding-left: 15rem;
  }

  &__main {
    width: 95%;

    @include responsive(tab-port) {
      width: 100%;
    }
  }

  &__side {
    min-width: 5.5rem;
    height: 100vh;
    padding: 1rem 0;

    @include responsive(tab-port) {
      display: none;
    }
  }
}

.section {
  width: 100%;
  height: auto;
  padding-top: 3.5rem;
  padding-right: 3rem;

  @include responsive(tab-land) {
    padding-right: 2rem;
  }

  @include responsive(tab-port) {
    padding-right: 0;
  }

  @include responsive(big-desktop) {
    padding-right: 10rem;
  }

  &--about {
    @include responsive(tab-port) {
      height: 80vh;
    }
  }

  @include responsive(phone-land) {
    height: fit-content;
  }
}
