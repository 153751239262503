.side-nav {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  background-color: $color-primary;
  display: flex;
  justify-content: center;
}

.side-nav-navigation {
  list-style-type: none;

  &__item {
    cursor: pointer;
    text-align: center;
  }

  &__item:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__icon {
    color: $color-secondary;
    width: 2.5rem;
    height: 2.5rem;
    transition: 0.2s;
  }

  &__icon:hover {
    color: $color-grayed-out;
  }
}
