.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6rem;
  padding-top: 1.5rem;

  @include responsive(tab-port) {
    height: 5rem;
    padding: 0 3rem;
  }
}

.regular-screen-size-nav {
  @include responsive(tab-port) {
    display: none;
  }
}
